@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
      url(/src/assets/fonts/montserrat/Montserrat-Medium.ttf);
  }

  @font-face {
    font-family: "digital";
    src: local("digital"), url(/src/assets/fonts/ds_digital/DS-DIGIT.TTF);
  }
}
@layer components {
  .form-name {
    @apply w-full bg-darkGrey border border-lightGrey h-14 pl-6 rounded-small text-sm text-white focus:outline-none mt-2.5;
  }
  .game-score-player-score {
    @apply text-3xl font-bold text-white font-digital;
  }
  .score-header {
    @apply p-2 text-white text-xl font-semibold;
  }
  .score-player-second {
    @apply p-2 w-extraMedium border-r-xs border-r-customGray text-center;
  }
}

body {
  font-family: "Montserrat";
}

.text-gradient {
  background: linear-gradient(to bottom, #00b3ff 40%, #00599b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modern-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.modern-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.modern-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(61, 63, 88, 0.75);
  border-radius: 10px;
}

.secondaryContainer {
  overflow: auto;
  border-collapse: collapse;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
}

.secondaryContainer thead.table-header th {
  width: 150px;
  white-space: nowrap;
  height: 30px;
  padding: 15px;
  position: sticky;
  background-color: #202130;
  top: 0;
}

@media (max-width: 768px), (max-height: 856px) {
  .screen-height {
    max-height: fit-content;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 90% !important;
  }
}
@media screen and (max-width: 992px) {
  .game-scrollbar {
    overflow: auto;
    border-collapse: collapse;
    /* border: 2px solid rgba(255, 255, 255, 0.3); */
  }
}

@media screen and (min-width: 768px) {
  .home-code {
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 100% !important;
    padding: 0 15px !important;
  }
}

/* @media screen and (max-width: 520px) {
  .container {
    max-width: 90% !important;
    padding: 0 15px !important;
  }
} */

@media screen and (min-width: 500px) {
  .button-responsive {
    height: 40px !important;
  }
}
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.toast-success {
  background-color: #0f7701 !important;
  opacity: 1 !important;
}

.toast-error {
  background-color: #bd362f !important;
  opacity: 1 !important;
}

#toast-container > .toast-success {
  background-image: unset !important;
}

.height {
  height: calc(100vh - 165px);
}

@media screen and (max-width: 992px) {
  .height {
    height: calc(100vh - 240px);
  }
}

/*Responsive header*/
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slide-up 0.3s ease-out;
}

.hero-image {
  background-image: url("../src/assets/images/hero-image.png");
}

@media (max-width: 1024px) {
  .hero-image {
    background-image: url("../src/assets/images/responsive-hero.png");
  }
}

/* ------------button hover effect ----------- */

.submit-button:hover {
  background-image: linear-gradient(to bottom, #3f9027 0%, #9ace24 100%);
  transform: scale(1.05);
}

@media (max-width: 1200px), (min-width: 1092px) {
  .home-code {
    margin-left: 10px !important;
  }
}
@media screen and (min-width: 992px) {
  .home-code {
    margin-left: 100px !important;
  }
}

.change-bg tr:nth-child(even) {
  background-color: #202130;
}
